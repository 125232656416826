import React, { useRef, useState } from 'react';
import '../css/login.css';

const URL_LOGIN = "https://kpi.winplh.com/php/login.php"

const enviarData = async (url, data) => {
    const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-type': 'application/json'
        }
    })

    const json = await resp.json();

    return json;
}

export default function Login(props) {
    const [error, setError] = useState(null);
    const [espera, setEspera]= useState(false);

    const refUsuario = useRef(null);
    const refContra = useRef(null);
    const handleLogin = async () => {
        setEspera(true);
        const data = {
            "usuario": refUsuario.current.value,
            "contra": refContra.current.value
        };

        console.log(data);
        const respuestaJson = await enviarData(URL_LOGIN, data);
        console.log("respuesta desde el evento", respuestaJson);
        props.acceder(respuestaJson.conectado);
        setError(respuestaJson.error)
        setEspera(false);
    };

    return (
        <div className="login pt-5">
            <div className="row">
                <div className="col-sm-4 offset-4 mt-5">
                    <div className="card pt-5">
                        <div className="card-header text-center">
                            <h3>🔎 Iniciar </h3>
                        </div>
                        <div className="card-body">
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                    📧
                                </span>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="correo"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    ref={refUsuario}
                                />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon2">
                                    🔑
                                </span>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="contraseña"
                                    aria-label="contraseña"
                                    aria-describedby="basic-addon2"
                                    ref={refContra}
                                />
                            </div>{
                                error &&
                                <div class="alert alert-danger" role="alert">
                                    {error}
                                </div>}
                            <div className="d-grid gap-2 col-12 mx-auto">
                                <button onClick={handleLogin} disabled={espera} className="btn btn-primary">Ingresar</button>
                            </div>
                            <br />
                            {/* <div className="card-footer">
                                <span>Olvido su contraseña?</span> <a href="#">Recuperar</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}