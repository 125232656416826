import React from 'react';

export default function Menu(params) {

    const handleLogout = () => {
        // Aquí puedes agregar la lógica para cerrar sesión
        console.log("Cerrando sesión...");
        // Ejemplo: Redirigir al usuario a la página de inicio de sesión
        window.location.href = '/';
    };
    return (
        <div className="fullscreen-container" style={{ position: 'relative', width: '100vw', height: '100vh', margin: 0, padding: 0 }}>

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <h1>HLC</h1>
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                </div>
            </nav>
            <button
                className="btn btn-danger"
                style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1000 }}
                onClick={handleLogout}
            >
                Cerrar Sesión
            </button>
            <iframe
                title="BBP01"
                src="https://app.powerbi.com/view?r=eyJrIjoiMjMxOWZkMWItMTJiMS00YmU0LWI5ODgtNjliNDZiZGVkYTVkIiwidCI6ImE2NjM0NGUwLTg3YTItNDI2Ni1iNjE0LThiOGE1M2E3MWIwNiIsImMiOjR9"
                frameBorder="0"
                allowFullScreen="true"
                style={{ width: '100%', height: '90%', border: 'none' }}
            />
        </div>
    )
}